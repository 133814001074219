import React from 'react'
import {Menu, MenuProps} from "antd";
import {
    BookOutlined,
    CalendarOutlined,
    HistoryOutlined,
    InboxOutlined,
    QuestionCircleOutlined,
    UserOutlined
} from "@ant-design/icons";
import {GradeMatterIcon} from "./GradeMatterIcon";
import {Session} from "../models";
import {useNavigate} from "react-router-dom";

type MainNavMenuProps = {
    session: Session;
}
export const MainNavMenu = (props: MainNavMenuProps) => {
    const navigate = useNavigate()

    const mainMenuItems: MenuProps['items'] = [
        {
            "key": "account",
            "icon": <UserOutlined/>,
            "label": "Account",
            onClick: () => navigate("/account"),
        },
        {
            "key": "courses",
            "icon": <BookOutlined/>,
            "label": "Courses",
            onClick: () => navigate("/courses"),
        },
        {
            "key": "calendar",
            "icon": <CalendarOutlined/>,
            "label": "Calendar",
            onClick: () => navigate("/calendar"),
        },
        {
            "key": "inbox",
            "icon": <InboxOutlined/>,
            "label": "Inbox",
            onClick: () => navigate("/inbox"),
        },
        {
            "key": "history",
            "icon": <HistoryOutlined/>,
            "label": "History",
            onClick: () => navigate("/history"),
        },
        {
            "key": "help",
            "icon": <QuestionCircleOutlined/>,
            "label": "Help",
            onClick: () => navigate("/help"),
        },
        {
            "key": "review_grades",
            "icon": <GradeMatterIcon />,
            "label": "Grade Matter",
            "onClick": () => {
                window.location.href = `/lti/login?username=${props.session.user.userId}`
            },
        },
    ];
    return (
        <Menu
            mode="inline"
            defaultSelectedKeys={['courses']}
            items={mainMenuItems}
        />
    )
}
