import {useQuery} from "@tanstack/react-query";
import {User} from "../models";

const useUsers = () => {
    return useQuery({
        queryKey: ["lmsusers"],
        queryFn: async (): Promise<User[]> => {
            const response = await fetch(`/ws/users`, {});
            return response.json();
        }
    });
}

export default useUsers;