import React from 'react';
import {Table} from 'antd';
import {Assessment, Course} from "../models";
import {useParams} from "react-router-dom";
import useAssessments from "../Data/useAssessments";
import useStudentGrades from "../Data/useStudentGrades";
import useCourse from "../Data/useCourse";

const buildColumns = (assessments: Assessment[]) => {
    const deduplicatedArray: (Assessment | undefined)[] = Array.from(new Set(assessments.map(a => a.id)), id => {
        return assessments.find(item => item.id === id)
    });

    const studentColumn = {
        title: 'Student',
        dataIndex: 'fullName',
        key: 'student',
    }
    const columns = [studentColumn];

    deduplicatedArray?.forEach((a) => {
        if (!a) {
            return;
        }
        columns.push({
            title: a.name,
            dataIndex: a.id,
            key: a.id,
        })
    })

    return columns;
}

export const Grades = () => {
    const { courseId } = useParams();

    const {data: course} = useCourse(courseId);
    const childrenIds = course?.children ? course.children.map((child: Course) => child.courseId) : [];
    const allCourseIds = courseId ? [courseId, ...childrenIds] : undefined;

    const { data: assessments} = useAssessments(allCourseIds);
    const { data: studentGrades, isLoading: studentGradesAreLoading } = useStudentGrades(courseId)

    return (
        <Table
            columns={buildColumns(assessments ?? [])}
            dataSource={studentGrades}
            loading={studentGradesAreLoading}
            pagination={{
                defaultPageSize: 100,
            }}
            bordered={true}
            style={{overflow: "scroll", maxHeight: "100%"}}
        />
    )
}
