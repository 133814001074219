const antdTheme = {
    token: {
        colorPrimary: '#242E31',
        colorSuccess: '#98AD90',
        colorWarning: '#B7A089',
        colorError: '#AE2F13',
        colorLinkReset: '#242E31',
        colorTextBase: '#000',
        borderRadius: 5
    },
    components: {
        Menu: {
            iconSize: 30,
            itemHeight: 50,
            itemSelectedBg: "#AFBCCA",
            activeBarWidth: 3,
        },
        Table: {
            cellFontSize: 16,
        },
        Form: {
            verticalLabelPadding: "5px 0",
            itemMarginBottom: 20,
            verticalLabelMargin: 0
        }
    }
};

export default antdTheme;