import {CustomIconComponentProps} from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";
import React from "react";

const GradeMatterSVG = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 82 79.1">
        <g>
            <path d="M41,16.1c-2.3-2-5-4.4-5.3-4.7c-2.1-2-4.4-3.8-6.8-5.4c2.4-0.6,4.9-0.9,7.4-0.9h9.3c2.5,0,4.9,0.3,7.3,0.9
                        c-2.4,1.6-4.7,3.4-6.8,5.4C45.8,11.9,42.6,14.7,41,16.1z"/>
            <g>
                <path d="M17,38.7c0,3.6,3,6.6,6.6,6.6c3.6,0,6.6-3,6.6-6.6c0-3.6-3-6.6-6.6-6.6C20,32.1,17,35.1,17,38.7"/>
                <path d="M51.8,38.7c0,3.6,3,6.6,6.6,6.6c3.6,0,6.6-3,6.6-6.6c0-3.6-3-6.6-6.6-6.6C54.8,32.1,51.8,35.1,51.8,38.7"/>
            </g>
            <path fill="#F0272B" d="M41,29.1c0,0-8.7-24.3-35.1-24.3L11,13c24.9,3,30,40.9,30,40.9S46.1,16,71,13l5.1-8.2
                        C49.7,4.8,41,29.1,41,29.1z"/>
            <path d="M12.3,44.1l-0.1-8.3c0-5.4,1.7-10.5,5.1-14.7c-2.3-1.3-4.6-2.2-7.2-2.6c-3.4,5.2-5.2,11.1-5.2,17.3v7.1c0,0.2,0,0.5,0,0.7
                        c0,0.2,0,0.3,0,0.5"/>
            <path d="M71.8,18.5c-2.5,0.5-4.9,1.3-7.2,2.6c0,0,0,0,0,0c0,0,0,0,0,0c3.3,4.2,5,9.3,5,14.6v7.1c0,13.2-10.8,24-24,24h-9.3
                        c-13.2,0-24-10.8-24-24v-7.1c0-5.3,1.7-10.4,5-14.6c-2.2-1.3-4.6-2.2-7.1-2.7C6.8,23.6,5,29.6,5,35.8v7.1c0,17.3,14,31.3,31.3,31.4
                        v0h0.1h9.3h0.1c0.3,0,0.7,0,1,0c0.2,0,0.4,0,0.6,0c0.4,0,0.8-0.1,1.2-0.1c0.2,0,0.3,0,0.5,0c0.3,0,0.7-0.1,1-0.1
                        c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.5-0.1,0.8-0.1c0.3-0.1,0.5-0.1,0.8-0.2c0.2,0,0.4-0.1,0.6-0.1c0.3-0.1,0.6-0.1,1-0.2
                        c0.2,0,0.4-0.1,0.5-0.2c0.3-0.1,0.7-0.2,1-0.3c0.2-0.1,0.3-0.1,0.5-0.2c0.4-0.1,0.7-0.2,1-0.4c0.2-0.1,0.3-0.1,0.5-0.2
                        c0.3-0.1,0.7-0.3,1-0.4c0.2-0.1,0.3-0.1,0.5-0.2c0.3-0.1,0.6-0.3,1-0.5c0.2-0.1,0.3-0.2,0.5-0.3c0.3-0.2,0.6-0.3,0.9-0.5
                        c0.2-0.1,0.4-0.2,0.6-0.3c0.3-0.1,0.5-0.3,0.7-0.5c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.3,0.6-0.4c0.3-0.2,0.5-0.4,0.8-0.5
                        c0.1-0.1,0.2-0.2,0.3-0.2c1.6-1.2,3.1-2.6,4.4-4.1l0-0.1c4.6-5.2,7.6-12,7.9-19.5l0-0.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.5,0-0.7v-7.1
                        C77.1,29.6,75.3,23.6,71.8,18.5z"/>
        </g>
    </svg>
)
export const GradeMatterIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={GradeMatterSVG} {...props} />
);