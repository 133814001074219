import {useQuery} from "@tanstack/react-query";
import {Assessment, Mark} from "../models";

type Tuple = [string, Mark[]];

const useMarks = (assessments: Assessment[]|undefined) => {
    const allAssessments = assessments ?? [];
    return useQuery({
        queryKey: [assessments, "marks"],
        queryFn: async () => {
            const markPromises = allAssessments.map(async (a) => {
                const response = await fetch(`/ws/courses/${a.courseId}/gradebook/assessments/${a.id}/marks`);
                const mark = await response.json();
                return [a.id, mark];
            });

            const entries = await Promise.all(markPromises)
            const consolidatedMarks =  joinMarksWithSameID(entries as Tuple[]);
            return Object.fromEntries(consolidatedMarks);
        }
    });
}

export default useMarks;

const joinMarksWithSameID = (entries: Tuple[]): Tuple[] => {
    const resultMap = new Map<string, Mark[]>();

    entries.forEach(([id, users]) => {
        const existingUsers = resultMap.get(id) || [];
        const joinedUsers = existingUsers.concat(users);
        resultMap.set(id, joinedUsers);
    });

    return Array.from(resultMap.entries());
};