import React, { createContext, useContext, useState } from 'react';
import { Session } from "../models";

type SessionContextType = {
    session: Session | undefined;
    setSession: (newSession: Session | undefined) => void;
};

const SessionContext = createContext<SessionContextType | undefined>(undefined);

interface SessionProviderProps {
    children: React.ReactNode;
}

const localStorageSession = localStorage.getItem('userSession');
const initialSession = localStorageSession ? JSON.parse(localStorageSession) : undefined;

export const SessionProvider: React.FC<SessionProviderProps> = ({ children }) => {
    const [session, setSession] = useState<Session | undefined>(initialSession);

    return (
        <SessionContext.Provider value={{ session, setSession }}>
            {children}
        </SessionContext.Provider>
    );
};


export const useSession = () => {
    const context = useContext(SessionContext);
    if (context === undefined) {
        throw new Error('useSession must be used within a SessionProvider');
    }
    return context;
};