import {Assessment, Course, Mark, User} from "../models";
import useMarks from "./useMarks";
import useUsers from "./useUsers";
import useAssessments from "./useAssessments";
import { useEffect, useState } from "react";
import useCourse from "./useCourse";

type StudentGrade = {
    fullName: string;
    [assessmentId: string]: string;
};

type StudentGradesState = {
    data: StudentGrade[] | undefined;
    isLoading: boolean;
};

const mapStudentGrades = (
    students: User[],
    assessments: Assessment[],
    marks: Record<string, Mark[]>
): StudentGrade[] => {
    return students.map((student) => {
        const studentMarks: Record<string, string> = {};

        assessments.forEach((assessment) => {
            const mark = marks[assessment.id].find((m) => m.externalUserKey === student.userId);

            if (mark) {
                studentMarks[assessment.id] = mark.maxScore === 0 ? "Exempt" : `${mark.score ?? "-"} / ${mark.maxScore}`;
            }
        });

        return {
            fullName: `${student.givenName} ${student.familyName}`,
            ...studentMarks,
            key: student.userId,
        };
    });
};

const useStudentGrades = (courseId: string | undefined) => {
    const [studentGradesState, setStudentGradesState] = useState<StudentGradesState>({
        data: undefined,
        isLoading: true,
    });

    const {data: course} = useCourse(courseId);
    const childrenIds = course?.children ? course.children.map((child: Course) => child.courseId) : [];
    const allCourseIds = courseId ? [courseId, ...childrenIds] : undefined;

    const { data: assessments } = useAssessments(allCourseIds);
    const { data: marks } = useMarks(assessments);


    const { data: allUsers} = useUsers();

    useEffect(() => {
        const courseUserIds = new Set(
            Object.values(marks ?? {})
                .flatMap((m) => (m as Mark[]).map((mark) => mark.externalUserKey))
        );
        if (assessments && marks && allUsers) {
            const courseUsers = allUsers.filter((u) => courseUserIds.has(u.userId));
            const mappedData = mapStudentGrades(courseUsers, assessments, marks);
            setStudentGradesState({
                data: mappedData,
                isLoading: false,
            });
        }
    }, [assessments, marks, allUsers]);

    return studentGradesState;
};

export default useStudentGrades;