import React from 'react'
import {List} from "antd";
import {useParams} from "react-router-dom";
import useAssessments from "../Data/useAssessments";

export const Assignments = () => {
    const { courseId } = useParams();
    const { data: assignments, isLoading: assignmentsAreLoading } = useAssessments(courseId ? [courseId] : undefined)

    return (
        <List
            itemLayout="horizontal"
            dataSource={assignments}
            loading={assignmentsAreLoading}
            renderItem={(item, index) => (
                <List.Item>
                    <List.Item.Meta
                        title={item.name}
                        description={`Points: ${item.pointsPossible}`}
                    />
                </List.Item>
            )}
        />
    )
}