import {useQuery} from "@tanstack/react-query";
import {Assessment} from "../models";

const useAssessments = (courseIds: string[] | undefined) => {
    const allIds = courseIds ?? [];
    return useQuery({
        queryKey: [courseIds, "assessments"],
        queryFn: async (): Promise<Assessment[]> => {
            const assessmentsPromises = allIds.map(async (id) => {
                const response = await fetch(`/ws/courses/${id}/gradebook/assessments`);
                const assessments = await response.json();

                return assessments.map((assessment: Assessment) => ({
                    ...assessment,
                    courseId: id,
                }));
            });

            const assessmentsWithCourseId = await Promise.all(assessmentsPromises);
            return assessmentsWithCourseId.flat();
        },
    });
}

export default useAssessments;