import React from 'react';
import {useNavigate, useParams, Outlet} from 'react-router-dom';
import {ConfigProvider, Layout, Menu} from "antd";
import {RollbackOutlined} from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import {useSession} from "../Data/useSession";
import useCourse from "../Data/useCourse";

export const Courses = () => {
    const navigate = useNavigate();
    const { courseId } = useParams();
    const { session } = useSession();
    const { data: course} = useCourse(courseId)

    const courseMenuItems = [
        {
            "key": "courses",
            "label": "Back to courses",
            onClick: () => navigate(`/courses`),
            icon: <RollbackOutlined />,
        },
        {
            "key": "home",
            "label": "Home",
            onClick: () => navigate(`/courses/${courseId}`),
        },
        {
            "key": "announcements",
            "label": "Announcements",
            onClick: () => navigate(`/courses/${courseId}/announcements`),
        },
        {
            "key": "assignments",
            "label": "Assignments",
            onClick: () => navigate(`/courses/${courseId}/assignments`),
        },
        {
            "key": "grades",
            "label": "Grades",
            onClick: () => navigate(`/courses/${courseId}/grades`),
        },
        {
            "key": "people",
            "label": "People",
            onClick: () => navigate(`/courses/${courseId}/people`),
        },
        {
            "key": "rubrics",
            "label": "Rubrics",
            onClick: () => navigate(`/courses/${courseId}/rubrics`),
        },
        {
            "key": "gradeMatter",
            "label": "Submit Final Grades",
            onClick: () => {
                window.location.href = `/lti/login?username=${session?.user.userId}&courseId=${courseId}`;
            }
        },
        {
            "key": "settings",
            "label": "Settings",
            onClick: () => navigate(`/courses/${courseId}/settings`),
        },
    ];

    return (
        <ConfigProvider
            theme={{
                components: {
                    Menu: {
                        itemHeight: 30,
                        itemSelectedBg: "rgba(175,188,202,0.47)",
                        itemMarginInline: 0,
                        activeBarWidth: 0,
                        itemBorderRadius: 0,
                        iconSize: 20,
                    },
                },
            }}>
                    <Layout style={{height: "100vh" }}>
                        <Sider width={200} style={{background: "white"}}>
                            <Menu
                                mode="inline"
                                defaultSelectedKeys={['home']}
                                selectedKeys={[window.location.pathname.split("/").pop() ?? "home"]}
                                items={courseMenuItems}
                                style={{minHeight: "100%"}}>
                            </Menu>
                        </Sider>
                        <Content
                            style={{
                                padding: "20px",
                                margin: 0,
                                background: "white",
                                height: "100%"
                            }}>
                            <h2 style={{margin: 0}}>{course?.title}</h2>
                            <br />
                            <Outlet />
                        </Content>
                    </Layout>

        </ConfigProvider>)
}
