import {useQuery} from "@tanstack/react-query";
import {Course} from "../models";

const useCourse = (courseId: string|undefined) => {
    return useQuery({
        queryKey: [courseId, "courses"],
        queryFn: async (): Promise<Course> => {
            const response = await fetch(`/ws/courses/${courseId}`);
            return response.json();
        }
    });
}
export default useCourse;