import React from 'react'
import {Course} from "../models";
import {Table} from "antd";
import {Link} from "react-router-dom";
import useCourses from "../Data/useCourses";
import {useSession} from "../Data/useSession";
import {Content} from "antd/es/layout/layout";

export const CourseList = () => {
    const {session} = useSession();
    const {data: courses, isLoading: coursesAreLoading} = useCourses(session?.user.userId)

    const filteredCourses = courses?.filter(course =>
        course.usersInRoles.instructor &&
        course.usersInRoles.instructor.includes(session?.user.userId!!)
    );

    const columns = [
        {
            title: 'Course',
            dataIndex: 'title',
            key: 'title',
            render: (text: string, record: Course) => <Link to={`/courses/${record.courseId}`}
                                                            type={"link"}>{text}</Link>
        },
        {
            title: 'Course ID',
            dataIndex: 'courseId',
            key: 'courseId'
        }
    ];

    return (
        <Content style={{maxHeight: '100%', overflowY: 'auto'}}>
            <Table
                dataSource={filteredCourses?.map((course) => ({...course, key: course.courseId}))}
                columns={columns}
                expandable={{showExpandColumn: false}}
                loading={coursesAreLoading}
                pagination={false}
                locale={{emptyText: "No courses found"}}
            />
        </Content>
    )

}
