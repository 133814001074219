import React from 'react'
import {Button, Col, Layout, Row, theme, Typography} from 'antd';
import {LogoutOutlined} from '@ant-design/icons';
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {MainNavMenu} from "../Components/MainNavMenu";
import {Helmet} from 'react-helmet';
import {useSession} from "../Data/useSession";

const { Text} = Typography;
const {Header, Content, Sider, Footer} = Layout;


export const Home: React.FC = () => {
    const { session, setSession } = useSession();
    const location = useLocation();
    const dynamicPageTitle = location.pathname.split("/")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .pop();

    const handleSignOut = () => {
        localStorage.removeItem('userSession');
        setSession(undefined);
    }

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    if (session) {
        return (
            <>
            <Helmet>
                <title>{`Mallard University | ${dynamicPageTitle}`}</title>
            </Helmet>
            <Layout style={{height: "100vh"}}>
                <Header>
                    <Row justify={"space-between"}>
                        <Col style={{display: "flex", gap: 90, color: "white", marginBottom: 20}}>
                            <img src="/media/logo_duckonly.png" alt="logo" className={"logoHeader"}/>
                            <h1 style={{margin: 0}}>Mallard University LMS</h1>
                        </Col>
                        <Col>
                            <Text style={{ color: "white", marginRight: 15 }}>Signed in as {session.user.givenName}</Text>
                            <Button icon={<LogoutOutlined/>} onClick={handleSignOut}>Sign Out</Button>
                        </Col>
                    </Row>
                </Header>
                <Layout>
                    <Sider width={200} style={{background: colorBgContainer}}>
                        <MainNavMenu session={session}/>
                    </Sider>
                    <Layout style={{padding: '24px'}}>
                        <Content style={{
                            padding: 0,
                            margin: 0,
                            background: colorBgContainer,
                        }}>
                            <Outlet />
                        </Content>
                        <Footer style={{height: 20, textAlign: "center", padding: 0}}>
                            <p>&copy; {new Date().getFullYear()} All The Ducks. All rights reserved.</p>
                        </Footer>
                    </Layout>
                </Layout>
            </Layout>
            </>
        );
    } else {
        return <Navigate to="/login"/>;
    }

}
