import React from 'react';
import './App.css';
import {Home} from './Pages/Home';
import {ConfigProvider} from "antd";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import antdTheme from "./theme";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {Login} from "./Pages/Login";
import GenericScreen from "./Components/GenericScreen";
import {CourseList} from "./Components/CourseList";
import {Courses} from "./Pages/Courses";
import {Assignments} from "./Components/Assignments";
import {Grades} from "./Components/Grades";
import {People} from "./Components/People";
import {SessionProvider} from "./Data/useSession";

const queryClient = new QueryClient();

function App() {

    return (
        <SessionProvider>
            <ConfigProvider theme={antdTheme}>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/" element={<Home/>}>
                                <Route path="/account" element={<GenericScreen>This is the account page in our mock
                                    LMS</GenericScreen>}/>
                                <Route index element={<Navigate to="/courses" />}/>
                                <Route path="courses" element={<CourseList/>} index={true}/>
                                <Route path="courses/:courseId" element={<Courses/>}>
                                    <Route index element={<div>This is a course landing page in our mock LMS</div>}/>
                                    <Route path="announcements"
                                           element={<div>This is an announcements page fo in our mock LMS</div>}/>
                                    <Route path="assignments" element={<Assignments/>}/>
                                    <Route path="grades"
                                           element={<Grades/>}/>
                                    <Route path="people"
                                           element={<People/>}/>
                                    <Route path="rubrics"
                                           element={<div>This is a rubrics page in our mock LMS</div>}/>
                                    <Route path="settings"
                                           element={<div>This is a settings page in our mock LMS</div>}/>
                                </Route>
                                <Route path="/calendar"
                                       element={<GenericScreen>This is the calendar page in our mock
                                           LMS</GenericScreen>}/>
                                <Route path="/inbox" element={<GenericScreen>This is the inbox page in our mock
                                    LMS</GenericScreen>}/>
                                <Route path="/history" element={<GenericScreen>This is the history page in our mock
                                    LMS</GenericScreen>}/>
                                <Route path="/help" element={<GenericScreen>This is the help page in our mock
                                    LMS</GenericScreen>}/>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </QueryClientProvider>
            </ConfigProvider>
        </SessionProvider>
    );
}

export default App;
