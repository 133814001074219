import React, {useState} from 'react'
import {Alert, AlertProps, AutoComplete, Button, Card, Col, Divider, Form, Input, Row, Space, Typography} from "antd";
import {User} from "../models";
import useUsers from "../Data/useUsers";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import {useSession} from "../Data/useSession";
const {Title} = Typography;

type LoginState = {
    alerts: {
        message?: React.ReactNode,
        type?: AlertProps["type"];
    };
    suggestedUsers: { value: string }[];
    isLoggingIn: boolean;
};

const loginTitle = <>
    <Row justify={"center"}>
        <img src="/media/mallard_logo_transparent_bg.png" alt="logo" className={"logo"}/>
    </Row>
    <Row justify={"center"}>
        <Title level={3} style={{margin: 7}}>LMS Sign In</Title>
    </Row>
</>

const initialLoginState: LoginState = {
    alerts: {
        message: undefined,
        type: undefined,
    },
    suggestedUsers: [],
    isLoggingIn: false,
};

export const Login = () => {
    const { setSession } = useSession();
    const [state, setState] = useState(initialLoginState);
    const [loginForm] = Form.useForm();
    const navigate = useNavigate();

    const { data: lmsUsers } = useUsers();

    const handleSearchUsers = (value: string) => {
        if (!value) {
            return
        }
        const suggestedUsers = lmsUsers?.filter((user) => {
            return user.userId.startsWith(value);
        }).map((user) => {
            return {value: user.userId};
        });

        if (suggestedUsers) {
            setState({...state, suggestedUsers})
        }
    }

    const handleSignIn = (userId: string) => {
        const user: User|undefined = lmsUsers?.find((user) => user.userId === userId);
        if (user) {
            setSession({ user });
            localStorage.setItem('userSession', JSON.stringify({ user }));
            navigate(`/courses`);
        } else {
            setState({
                ...state,
                alerts: {message: "Invalid username or password", type: "error"},
                isLoggingIn: false
            })
        }
    }

    return (
        <div className={"LoginPage"}>
            <Helmet>
                <title>Mallard University | Sign In</title>
            </Helmet>
            <Card title={loginTitle}
                  bordered={false}
                  style={{width: 400}}
                  bodyStyle={{padding: "5px 20px 20px 20px"}}
            >

                {state.alerts.type ? <Alert
                    message={state.alerts.message}
                    type={state.alerts.type}
                    showIcon
                    style={{ marginBottom: 20 }}
                /> : <></>}
                <Form
                    layout={"vertical"}
                    form={loginForm}
                    onFinish={data => {
                        setState({...state, isLoggingIn: true})
                        handleSignIn(data.userId)
                    }}
                    style={{padding: 0}}
                >
                    <Form.Item label={<b>Username</b>} name={"userId"} rules={[{required: true}]} style={{ margin: "10px 0"}}>
                        <AutoComplete
                            options={state.suggestedUsers}
                            onSearch={handleSearchUsers}/>
                    </Form.Item>
                    <Form.Item label={<b>Password</b>} name={"password"} rules={[{required: true}]}>
                        <Input type={"password"}/>
                    </Form.Item>

                    <Row justify={"space-between"} align={"middle"}>
                        <Col>
                            <Form.Item noStyle={true}>
                                <Button type={"primary"} htmlType={"submit"} loading={state.isLoggingIn}>Sign In</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Divider>Or</Divider>
                <Space direction={"vertical"} align={"center"} style={{ width: "100%"}} size={15}>
                    <Button
                        type={"primary"}
                        onClick={() => handleSignIn("sargo")}>
                        Sign in as Instructor
                    </Button>
                    <Button
                        type={"primary"}
                        onClick={() => handleSignIn("lthomson")}>
                        Sign in as Review Board
                    </Button>
                </Space>
            </Card>
        </div>
    )
}
