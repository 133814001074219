import React from 'react'
import {useParams} from "react-router-dom";
import useCourse from "../Data/useCourse";

export const People = () => {
    const { courseId } = useParams();
    const { data: course} = useCourse(courseId)

    return (
        <>
            <h2>Instructors:</h2>
            <ul>
                {course?.usersInRoles.instructor?.map((instructor) => (
                    <li key={instructor}>{instructor}</li>
                ))}
            </ul>
        </>
    )
}
