import {useQuery} from "@tanstack/react-query";
import {Course} from "../models";

const useCourses = (userId: string|undefined) => {
    return useQuery({
        queryKey: [userId, "courses"],
        queryFn: async (): Promise<Course[]> => {
            const response = await fetch(`/ws/users/${userId}/courses`);
            return response.json();
        }
    });
}

export default useCourses;